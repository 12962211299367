let key = {};
if (process.env.REACT_APP_MODE === "production") {
    console.log("Set Production Config")


    //live url
    const API_URL = 'https://producationicoapi.mudra.exchange/';

    const contractAddr = '41b395dd23ebc23d55196a282bbfd1147138112262';
    const smartcontract = 'TSLmV7oPkF1jCbHkG18KmaFteAWgVJcAkV';
    const owneraddr = 'TFA8qFHRx15haCJzVttSeHyEhY3xzSAcnH';
    const EthOwnerAddr = "0x72ee890449fAbAd5f551C28bEEB19307166CD2B1";


    const MUDRA_CONTRACT_ADDRESS = "0x28e4972C2F2aB76aF9303586dc5dFCbcA06948fD";


    key = {
        secretOrKey: "",
        Recaptchakey: "", //local

        API: `${API_URL}`,
        contractAddr: contractAddr,
        smartcontract: smartcontract,
        owneraddr: owneraddr,
        EthOwnerAddr: EthOwnerAddr,
        MUDRA_CONTRACT_ADDRESS:MUDRA_CONTRACT_ADDRESS,
    };

} else if (process.env.REACT_APP_MODE === "demo") {
    console.log("Set Production Config")


    //live url
    const API_URL = 'https://mudraicoapi.wealwin.com/';

    const contractAddr = '41b395dd23ebc23d55196a282bbfd1147138112262';
    const smartcontract = 'TSLmV7oPkF1jCbHkG18KmaFteAWgVJcAkV';
    const owneraddr = 'TFA8qFHRx15haCJzVttSeHyEhY3xzSAcnH';
    const EthOwnerAddr = "0x72ee890449fAbAd5f551C28bEEB19307166CD2B1";
    const MUDRA_CONTRACT_ADDRESS = "0xaFF4481D10270F50f203E0763e2597776068CBc5";
    key = {
        secretOrKey: "",
        Recaptchakey: "", //local

        API: `${API_URL}`,
        contractAddr: contractAddr,
        smartcontract: smartcontract,
        owneraddr: owneraddr,
        EthOwnerAddr: EthOwnerAddr,
        MUDRA_CONTRACT_ADDRESS:MUDRA_CONTRACT_ADDRESS,

    };
    

} else {
    console.log("Set Development Config")
    const API_URL = 'http://localhost';
    const contractAddr = '41b395dd23ebc23d55196a282bbfd1147138112262';
    const smartcontract = 'TSLmV7oPkF1jCbHkG18KmaFteAWgVJcAkV';
    const owneraddr = 'TFA8qFHRx15haCJzVttSeHyEhY3xzSAcnH';
    const EthOwnerAddr = "0x72ee890449fAbAd5f551C28bEEB19307166CD2B1";
    const MUDRA_CONTRACT_ADDRESS = "0xaFF4481D10270F50f203E0763e2597776068CBc5";
    key = {
        secretOrKey: "",
        Recaptchakey: "", //local

        API: `${API_URL}:2054`,
        contractAddr: contractAddr,
        smartcontract: smartcontract,
        owneraddr: owneraddr,
        EthOwnerAddr: EthOwnerAddr,
        // ImageUrl:`${API_URL}:3001`
        MUDRA_CONTRACT_ADDRESS:MUDRA_CONTRACT_ADDRESS,


    };
    

}



export default key;