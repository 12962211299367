import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import { MultiSelect } from "react-multi-select-component";

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from '../../lib/isEmpty';

import {  getemailTemplate,updateEmailTemplate,getSubscripeUsers,sendSupscripeEmail } from '../../actions/users';



const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const options = [
  { label: "Grapes 🍇", value: "grapes" },
  { label: "Mango 🥭", value: "mango" },
  { label: "Strawberry 🍓", value: "strawberry", disabled: true },
];

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}


const initialFormValue = {
  'identifier': "",
  'subject': "",
  'content': "",
}

const useStyles = makeStyles(styles);

export default function UserProfile(props) {
  
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [userdet, setUser] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectemail,setSelectEmail]=useState([]);
  const [emailContent,setEmailContent]=useState("");

  const { Id } = useParams();
   // console.log(userId,"asdfdsfdsfdsf");


  // function
  const onChange = (e) => {
    e.preventDefault();
    console.log(e.target); 
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
    console.log(formValue);
    //setValidateError(formData)
  }
  const onchangeeditor = (e) => {
    let formData = { ...formValue, ...{ ["content"]: e } }
    setEmailContent(formData);
    setFormValue(formData)
    console.log(formValue);
  }

    const {
      identifier,
      subject,
      content,
    } = formValue

  const handleFormSubmit = async (e) => {

    //console.log("saran");
    e.preventDefault();
    console.log("selectemailselectemail",selectemail);
    console.log("emailContent",emailContent);
  

    let reqData = {
        selectemail,
        emailContent,
    }
    console.log(reqData,"ddd");
    let { error, result} = await sendSupscripeEmail(reqData);
    console.log(error);
    if (isEmpty(error)) {
      toast.success(result, toasterOption); 
     // history.push('/emailTemplateList');
    } else {
      setValidateError(error);
    }
  }
  const seletct=(data)=>{

    setSelectEmail(data);
    


}
//    const getEmailTemplateData = async () => {

    
//     var test = await getemailTemplate(Id);
//     console.log("emailTemplate",test);

//     let formdata = {};
//     formdata['identifier'] = test.userValue.identifier;
//     formdata['subject'] = test.userValue.subject;
//     formdata['content'] = test.userValue.content;
//     setFormValue(formdata)
   
//   }
  const getSubscripeEmail=async () => {
    var {result,message} = await getSubscripeUsers();
    console.log("resuletjeeieirjeirerieere",result);
     var arremail1 =[]; 

    result.map((item,i)=>{
                
                    const label=item.email
                    const value = item.email;  
                    const obj1={label:label,value:value}       
                   
                     arremail1.push(obj1) ; 

                   });
                   
                   setSelected(arremail1);
             

  }

  useEffect(() => {
    //logout(history)
    getSubscripeEmail();
    
    //getEmailTemplateData();
  }, [])

 const back = async () => {
     window.location.href='/Mudrattrqqqq';
    }
  return (
    <div>
     <button className="btn btn-success mr-3" onClick={() => back()}>Back</button>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>EmailTemplate update</h4>
                <p className={classes.cardCategoryWhite}>Update your pages</p>
              </CardHeader>
              <CardBody>
                <GridContainer>                 
                    <GridItem xs={12} sm={12} md={3}>
                      {/* <CustomInput
                        labelText="Identifier"
                        onChange={onChange}
                        id="identifier"
                        value={identifier || ''}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                      {
                          validateError.identifier && <span className={classes.textDanger}>{validateError.identifier}</span>
                      } */}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      {/* <CustomInput
                        labelText="Subject"
                        onChange={onChange}
                        id="subject"
                        value={subject || ''}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                      {
                          validateError.subject && <span className={classes.textDanger}>{validateError.subject}</span>
                      } */}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                    <MultiSelect
                      options={selected}
                      value={selectemail}
                      onChange={(data)=>seletct(data)}
                      labelledBy="Select"
                    />
                      {/* {
                          validateError.subject && <span className={classes.textDanger}>{validateError.subject}</span>
                      } */}
                    </GridItem>
                    
                  </GridContainer>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                    <p className={classes.cardCategoryWhite}>Create a new user</p>
                    <Editor apiKey='zwj2xxsfjb1nxd9f7qzayqsgyxf358u7z4dexqegdt9mjk4j'
                      initialValue="<p>This is the initial content of the editor</p>"
                      value={content} 
                      onEditorChange={onchangeeditor}
                      id="content"
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                          'undo redo code | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help'
                      }}
                    />
                   
                    {
                      validateError.content && <span className={classes.textDanger}>{validateError.content}</span>
                    }
                  </GridItem>
                
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">Send</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>       
      </GridContainer>
    </div>
  );
}
